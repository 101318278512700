import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInput } from '../InputProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export function Step3Component({ onNext, onBack }) {
  const { logAllValues, roofingMaterial, setRoofingMaterial, setRoofingMaterialPrice } = useInput();
  
  const [initialRender, setInitialRender] = useState(() => {
    const storedInitialRender = localStorage.getItem('initialRenderStep2');
    return storedInitialRender ? JSON.parse(storedInitialRender) : true;
  });
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [radioOptions, setRadioOptions] = useState([]);

  const { register, handleSubmit, trigger, formState: { errors } } = useForm({
    defaultValues: {
      roofingMaterial: roofingMaterial || '',
    },
  });

  const onSubmit = data => {
    logAllValues();
    onNext();
  };

  useEffect(() => {
    setIsNextDisabled(!(roofingMaterial));
  }, [roofingMaterial]);

  useEffect(() => {
    if (initialRender) {
      localStorage.setItem('initialRenderStep2', JSON.stringify(false));
      setInitialRender(false);
    }
  }, [initialRender]);

  useEffect(() => {
    // Fetch materials from API
    axios.get('https://beta.dashboard.roofproroofing.com/materials')
      .then(response => {
        const materials = response.data.materials;
        const options = materials.map(material => ({
          name: material.material_type.toLowerCase().replace(/\s+/g, '_'),
          label: material.material_type,
          price: 0 // Default price, adjust as needed
        }));
        setRadioOptions(options);
      })
      .catch(error => {
        console.error('Error fetching materials:', error);
      });
  }, []);

  const handleRadioSelection = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = radioOptions.find(option => option.label === selectedValue);
    const selectedPrice = selectedOption ? selectedOption.price : null;
    setRoofingMaterial(selectedValue);
    setRoofingMaterialPrice(selectedPrice);

    // Trigger validation on change
    trigger('roofingMaterial');
  }

  return (
    <div className='items-center justify-center'>
      <div className="relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl">
        Roofing calculator
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex relative mb-5 gap-4 text-xl justify-center text-left'>
          <div className="relative mt-10 max-w-full max-sm:mt-10">
            <div className="flex gap-5 max-sm:flex-col max-sm:gap-0 max-sm:px-4">
              <div className="flex flex-col max-sm:ml-0 max-sm:w-full">
                <div className="flex relative grow gap-5 justify-between max-sm:mt-10">
                  <div className="flex flex-col justify-center items-center flex-1 self-start">
                    <div className="text-sm mb-2 font-bold text-center text-dark uppercase tracking-[1.6px]">
                      Which roofing material will you choose?
                    </div>

                    {radioOptions.map((option, index) => (
                      <div key={index} className="flex gap-2 mt-3 text-sm radio-option-set2">
                        <label className="flex items-center cursor-pointer">
                          <input 
                            type="radio" 
                            id={option.name}
                            className="hidden" 
                            name="roofingMaterial" 
                            value={option.label} 
                            {...register('roofingMaterial', { required: true })}
                            checked={roofingMaterial === option.label} // Handle checked state
                            onChange={handleRadioSelection} 
                          />
                          <div className="flex flex-col justify-center p-1 rounded-full border border-cyan-600 border-solid basis-0 stroke-[1px]">
                            <div className={`radio-button-set2 h-[5px] w-[5px] ${roofingMaterial === option.label ? 'shrink-0 bg-cyan-600 rounded-full' : ''}`} />
                          </div>
                          <div className="pl-2 flex-auto" style={{ textAlign: 'left', minWidth: '200px' }}>{option.label}</div>
                        </label>
                      </div>
                    ))}

                    {errors.roofingMaterial && (
                      <div className="text-red-500 text-sm flex items-center mt-2 flex"
                        style={{ marginLeft: '-7px' }}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        Please select roofing material
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='sm:pr-3'>
          <button
            onClick={onBack}
            className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
            Back
          </button>
          <button
            type="submit"
            className={`main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer`}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}
