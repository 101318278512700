import React, { Component } from 'react'

export class FooterComponent extends Component {
    render() {
        return (
            <div className="flex flex-col text-left px-16 pt-20 max-md:pt-0 pb-9 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col mt-11 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b188353701bc0e56468cca25ae2c96f79f5dbe1df66571f3f809ade0413a023?apiKey=7d33720f90a946d189f293ed1460be5b&"
                                        className="max-w-full aspect-[1.41] w-[271px]"
                                    />
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/afe0ac64f0a8ed72c97f389d7f75c689f37b8e50f5d5f19e7580f48bddabf889?apiKey=7d33720f90a946d189f293ed1460be5b&"
                                        className="mt-12 max-w-full aspect-[11.11] w-[273px] max-md:mt-10"
                                    />
                                    <div className="mt-40 text-xl font-light text-cyan-600 max-md:mt-10 max-md:max-w-full">
                                        <span className="">Call us now for a</span>{" "}
                                        <span className="font-bold text-cyan-600">
                                            free estimate.
                                        </span>
                                    </div>
                                    <div className="mt-3 text-3xl text-white max-md:max-w-full">
                                        850-332-3330 | 941-456-4496
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-[62%] max-md:ml-0 max-md:w-full">
                                <div className="mt-1.5 max-md:mt-10 max-md:max-w-full">
                                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">

                                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col text-xl leading-9 text-white max-md:mt-10">
                                                <div className="font-semibold">Service</div>
                                                <div className="text-base font-light leading-9 mt-10 text-white max-md:mt-10">
                                                    Residential
                                                    <br />
                                                    Commercial
                                                    <br />
                                                    24/7 Emergency Roofing Services
                                                    <br />
                                                    Roof Leak Prevention <br />
                                                    Roof Replacement
                                                    <br />
                                                    Roof Repair
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col text-xl leading-9 text-white max-md:mt-10">
                                                <div className="font-semibold">Service Areas</div>
                                                <div className="text-base font-light leading-9 mt-10 text-white max-md:mt-10">
                                                    Perry Florida
                                                    <br />
                                                    Watercolor
                                                    <br />
                                                    Blue Mountain Beach
                                                    <br />
                                                    Crestview
                                                    <br />
                                                    Defuniak Springs
                                                    <br />
                                                    Destin
                                                    <br />
                                                    Fort Walton Beach
                                                    <br />
                                                    Freeport
                                                    <br />
                                                    Grayton Beach
                                                    <br />
                                                    Gulf Breeze
                                                    <br />
                                                    Inlet Beach
                                                    <br />
                                                    Mary Ester
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col text-xl leading-9 text-white max-md:mt-10">
                                                <div className="font-semibold">Contact</div>
                                                <div className="flex gap-3.5 mt-14 whitespace-nowrap max-md:mt-10">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f363c2b3e5ef9ac46bf3423de119f9edd1fb685b0c5c8304cb73c0db45debae?apiKey=7d33720f90a946d189f293ed1460be5b&"
                                                        className="shrink-0 aspect-[0.95] fill-cyan-600 w-[22px]"
                                                    />
                                                    <div className="flex-auto my-auto">850-332-3330</div>
                                                </div>
                                                <div className="flex gap-4 mt-11 whitespace-nowrap max-md:mt-10">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff16f248f35487003f6a9e6b19b59d1c7983917c0c6d87692a64def40fab5528?apiKey=7d33720f90a946d189f293ed1460be5b&"
                                                        className="shrink-0 self-start aspect-[1.32] fill-cyan-600 w-[25px]"
                                                    />
                                                    <div className="grow">info@roofproroofing.com</div>
                                                </div>
                                                <div className="flex gap-5 mt-9">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c20bb549c5e26634d55caca9aee20b7d7df1dad3582f358eae254b2f00a10db?apiKey=7d33720f90a946d189f293ed1460be5b&"
                                                        className="shrink-0 w-5 aspect-[0.8]"
                                                    />
                                                    <div className="flex-auto">California, USA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/eaee15c95ca0dcb9c5d0d47f5c3ae217dff694abbf5b191cdb43903e53019fc5?apiKey=7d33720f90a946d189f293ed1460be5b&"
                        className="mt-16 w-full border-2 h-0 border-cyan-600 border-solid stroke-[2px] stroke-cyan-600 max-md:mt-10 max-md:max-w-full"
                    />
                    <div className="self-center mt-8 text-base leading-9 text-white whitespace-nowrap">
                        Copyright © 2024 Roofpro Roofing All rights reserved.
                    </div>
                </div>
            </div>
        )
    }
}
