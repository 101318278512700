import React, { useEffect, useState } from 'react'
import { useInput } from '../InputProvider';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export function Step9Component({ doneStage, onBack }) {

  const {
    zipCode,
    roofingProjectPrice,
    roofingMaterialPrice,
    buildingStoriesPrice,
    areaOfRoof,
    roofingProject,
    roofingMaterial,
    buildingStories,
    highRangeCost,
    midRangeCost,
    lowRangeCost,
    address,
    capturedImage,
    firstname,
    lastname,
    email,
    phone,
    appointmentOneDate,
    appointmentOneTime,
  } = useInput();

  const { logAllValues } = useInput(); // here it prints all the stored values

  // const baseCost = roofingProjectPrice + roofingMaterialPrice + buildingStoriesPrice;
  // const areaFactor = areaOfRoof / 1000; // Assuming 1000 sq ft as the base area

  // Calculate the high range cost
  // const highRangeCostFactor = 1.5;
  //highRangeCost = 0//baseCost * (1 + highRangeCostFactor * areaFactor);

  // Calculate the mid range cost
  // const midRangeCostFactor = 1.2;
  //midRangeCost = 0//baseCost * (1 + midRangeCostFactor * areaFactor);

  // Calculate the low range cost
  // const lowRangeCostFactor = 0.9;
  //lowRangeCost = 0//baseCost * (1 + lowRangeCostFactor * areaFactor);

  //send a post to https://beta.dashboard.roofproroofing.com/settings/cost with area type
  //and get the cost of the roof


  // handle DONE
  const handleDone = async () => {
    doneStage();
  }
  
  // const handleDone = async () => {
  //   try {
  //     const response = await fetch('https://beta.dashboard.roofproroofing.com/store-request', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         zipCode,
  //         roofingProject,
  //         roofingMaterial,
  //         buildingStories,
  //         roofingProjectPrice,
  //         roofingMaterialPrice,
  //         buildingStoriesPrice,
  //         areaOfRoof,
  //         highRangeCost,
  //         midRangeCost,
  //         lowRangeCost,
  //         address,
  //         capturedImage,
  //         firstname,
  //         lastname,
  //         email,
  //         phone,
  //         appointmentOneDate,
  //         appointmentOneTime,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();
  //     console.log('Success:', data);
  //     doneStage();
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };


  //onload
  useEffect(() => {
    logAllValues();
  }, [logAllValues]);


  return (
    <div className='items-center justify-center'>

      <div className="relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl">
        Roofing calculator
      </div>

      <div className="mt-7 text-sm font-bold tracking-[5.48px] uppercase text-stone-900">
        Estimate for
      </div>

      <div className="mt-3.5 mb-5 text-sm font-bold text-stone-950">
        {useInput().address}
      </div>

      {/* <div className='flex overflow-hidden relative flex-col items-center pb-0 w-full max-sm:max-w-full'>
        <div className="flex relative flex-col px-5 mt-1 ml-16 max-w-full font-bold w-[882px] max-sm:mt-10" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          
          <div className="flex gap-5 justify-between mt-11 max-sm:flex-wrap max-sm:mt-10 text-center">
            <div className="shrink my-auto text-2xl uppercase whitespace-nowrap basis-auto grow-0 tracking-[4.16px]">
              Estimate for
            </div>
            <div className="flex flex-auto gap-5 justify-between px-9 py-1 text-lg bg-cyan-600 max-sm:flex-wrap max-sm:px-5 max-sm:max-w-full">
              <div className="flex-auto my-auto text-white">
                {useInput().address}
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/09ac2cde0fadc4d8f18e3a643af6f4bc7e4a0726bc48e76979c0a68416822c6e?apiKey=7d33720f90a946d189f293ed1460be5b&"
                className="shrink-0 w-4 aspect-[0.69] mx-auto fill-white"
              />
            </div>
          </div>
        </div>

        <div className="relative mt-8 max-w-full w-[1006px]">
          <div className="flex gap-5 max-sm:flex-col max-sm:gap-0">
            <div className="flex flex-col w-2/5 max-sm:ml-0 max-sm:w-full">
              <img
                loading="lazy"
                src={useInput().capturedImage}
                className="w-full aspect-[1.35] w-[450px] max-sm:mt-6"
              />

            </div>
            <div className="flex flex-col ml-5 w-3/5 max-sm:ml-0 max-sm:w-full">
              <div className="flex relative flex-col px-5 mt-2 font-bold max-sm:mt-8 max-sm:max-w-full">
                <div className="text-xl uppercase tracking-[2.6px] max-sm:max-w-full">
                  Price breakdown
                </div>
                <div className="flex gap-5 justify-between mt-5 whitespace-nowrap max-sm:flex-wrap">
                  <div className="flex flex-col flex-1 px-6 py-2 text-cyan-600 bg-white max-sm:px-5">
                    <div className="text-xl">High Range Cost</div>
                    <div className="mt-2 text-3xl">${highRangeCost.toFixed(2).toLocaleString()}</div>
                  </div>
                  <div className="flex flex-col flex-1 my-auto">
                    <div className="text-xl text-cyan-600">Mid Range Cost</div>
                    <div className="mt-2 text-3xl">${midRangeCost.toFixed(2).toLocaleString()}</div>
                  </div>
                  <div className="flex flex-col flex-1 px-7 py-2 bg-cyan-600 max-sm:px-5">
                    <div className="text-xl">Low Range Cost</div>
                    <div className="mt-2 text-3xl">${lowRangeCost.toFixed(2).toLocaleString()}</div>
                  </div>
                </div>
                <div className="mt-11 text-xl text-left uppercase tracking-[2.6px] max-sm:mt-10 max-sm:max-w-full">
                  Details
                </div>
                <div className="flex gap-5 justify-between self-start mt-6 whitespace-nowrap max-sm:ml-1.5">
                  <div className="flex flex-col flex-1">
                    <div className="text-xl text-cyan-600">Roof area</div>
                    <div className="mt-4" style={{ fontSize: '20px' }}>{useInput().areaOfRoof.toLocaleString()} ft<sup>2</sup></div>
                  </div>
                  <div className="flex flex-col flex-1">
                    <div className="text-xl text-cyan-600">Material</div>
                    <div className="mt-4" style={{ fontSize: '20px' }}>{useInput().roofingMaterial}</div>
                  </div>
                  <div className="flex flex-col flex-1 self-start">
                    <div className="text-xl text-cyan-600">Stories</div>
                    <div className="mt-4" style={{ fontSize: '20px' }}>{useInput().buildingStories}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="bg-card text-card-foreground p-6 rounded-lg space-y-6">
        <div className="space-y-2">
          <h2 className="text-lg font-semibold">PRICE BREAKDOWN</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-gray-950 text-primary-foreground p-4 rounded-lg shadow-md">
              <h3 className="text-zinc-50">High Range Cost</h3>
              <p className="text-zinc-50 text-2xl font-bold">${highRangeCost.toLocaleString()}</p>
            </div>
            <div className="bg-zinc-300 text-card-foreground p-4 rounded-lg shadow-md">
              <h3 className="text-primary">Mid Range Cost</h3>
              <p className="text-2xl font-bold">${midRangeCost.toLocaleString()}</p>
            </div>
            <div className="bg-gray-950 text-primary-foreground p-4 rounded-lg shadow-md">
              <h3 className="text-zinc-50">Low Range Cost</h3>
              <p className="text-zinc-50 text-2xl font-bold">${lowRangeCost.toLocaleString()}</p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="text-lg font-semibold">DETAILS</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
            <div>
              <h4 className="text-muted-foreground">Roof area</h4>
              <p className="text-xl font-semibold">{areaOfRoof.toLocaleString()} ft²</p>
            </div>
            <div>
              <h4 className="text-muted-foreground">Material</h4>
              <p className="text-xl font-semibold">{roofingMaterial}</p>
            </div>
            <div>
              <h4 className="text-muted-foreground">Stories</h4>
              <p className="text-xl font-semibold">{buildingStories}</p>
            </div>
          </div>
        </div>
      </div>


      <div className='sm:pr-3 mt-5'>
        <button
          onClick={onBack}
          className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
          Back
        </button>
        <button
          onClick={handleDone}
          className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
          Done
        </button>
      </div>

    </div>
  )
}
