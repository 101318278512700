import React, { useEffect, useState  } from 'react';
import { useForm } from 'react-hook-form';
import { useInput } from '../InputProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const radioOptionsSet3 = [
  { name: 'one_story', label: 'One story', price: 5000 },
  { name: 'two_story', label: 'Two stories', price: 8000 },
  { name: 'three_story', label: 'Three stories', price: 10000 },
  { name: 'four_story', label: 'Four stories', price: 12000 },
];

export function Step4Component({onNext, onBack }) {

  const { logAllValues } = useInput();
  
  const { buildingStories, setBuildingStories, setBuildingStoriesPrice } = useInput();

  const [initialRender, setInitialRender] = useState(() => {
    const storedInitialRender = localStorage.getItem('initialRenderStep2');
    return storedInitialRender ? JSON.parse(storedInitialRender) : true;
  });
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const { register, handleSubmit, trigger, formState: { errors } } = useForm({
    defaultValues: {
      buildingStories: buildingStories || '',
    },
  });

  const onSubmit = data => {
    logAllValues();
    onNext();
  };

  // Check if at least one option is selected in each set
  useEffect(() => {
    setIsNextDisabled(!(buildingStories));
  }, [buildingStories]);

  useEffect(() => {

    if (initialRender) {
      const initialBuildingStories = radioOptionsSet3[0].label;
      setBuildingStories(initialBuildingStories);
      localStorage.setItem('initialRenderStep2', JSON.stringify(false));
      setInitialRender(false);
    }

    const allOptionsSet3 = document.querySelectorAll('.radio-option-set3');

   allOptionsSet3.forEach(option => {
    const radioIcon = option.querySelector('.radio-button-set3');
    if (option.querySelector('input').value === buildingStories) {
     radioIcon.classList.add('shrink-0', 'bg-cyan-600', 'rounded-full');
     option.querySelector('input').checked = true;
    } else {
     radioIcon.classList.remove('shrink-0', 'bg-cyan-600', 'rounded-full');
     option.querySelector('input').checked = false;
    }
   })
   
  
  });

    const handleRadioSelection = (event) => {
      const selectedValue = event.target.value;
      const clickedGroupName = event.target.name;
    
      const allOptionsSet3 = document.querySelectorAll('.radio-option-set3');

     // Check which group of radio buttons is being interacted with
     if (clickedGroupName === 'buildingStories') {
        const selectedOption = radioOptionsSet3.find(option => option.label === selectedValue);
        const selectedPrice = selectedOption ? selectedOption.price : null;
        setBuildingStories(selectedValue);
        setBuildingStoriesPrice(selectedPrice);

        // Handle Set 3 radio buttons
        allOptionsSet3.forEach(option => {
          const radioIcon = option.querySelector('.radio-button-set3');
          if (option.querySelector('input').value === selectedValue) {
            radioIcon.classList.add('shrink-0', 'bg-cyan-600', 'rounded-full');
            option.querySelector('input').checked = true;
          } else {
            radioIcon.classList.remove('shrink-0', 'bg-cyan-600', 'rounded-full');
            option.querySelector('input').checked = false;
          }
        });
      }

      // Trigger validation on change
      trigger('buildingStories');

    }

  return (
    <div className='items-center justify-center'>
        <div className="relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl">
          Roofing calculator
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex relative mb-5 gap-4 text-xl justify-center text-left'>
            <div className="relative mt-10 max-w-full max-sm:mt-10">
              <div className="flex gap-5 max-sm:flex-col max-sm:gap-0 max-sm:px-4">
                <div className="flex flex-col max-sm:ml-0 max-sm:w-full">
                  <div className="flex relative grow gap-5 justify-between max-sm:mt-10">

                    <div className="flex flex-col justify-center items-center flex-1 self-start">
                      <div className="text-sm mb-2 font-bold text-center text-dark uppercase tracking-[1.6px]">
                        How many stories is your building?
                      </div>

                      {radioOptionsSet3.map((option, index) => (
                          <div key={index} className="flex gap-2 mt-3 text-sm radio-option-set3">
                            <label className="flex items-center cursor-pointer">
                              <input 
                                type="radio" 
                                id='{option.name}' 
                                className="hidden" 
                                name="buildingStories" 
                                value={option.label} 
                                {...register('buildingStories', { required: true })}
                                onChange={handleRadioSelection} 
                              />
                              <div className="flex flex-col justify-center p-1 rounded-full border border-cyan-600 border-solid basis-0 stroke-[1px]">
                                <div className={`radio-button-set3 h-[5px] w-[5px] ${index === 0 ? 'shrink-0 bg-cyan-600 rounded-full' : ''}`} />
                              </div>
                              <div className="pl-2 flex-auto" style={{ textAlign: 'left', minWidth: '150px' }}>{option.label}</div>
                            </label>
                          </div>
                      ))}

                      {errors.buildingStories && (
                        <div className="text-red-500 text-sm flex items-center mt-2 flex"
                        style={{ marginLeft: '-12px' }}
                        >
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                          Please select a story
                        </div>
                      )}

                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
          <div className='sm:pr-3'>
            <button
              onClick={onBack}
              className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
              Back
            </button>
            <button
              type="submit"
              className={`main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer`}
            >
            Next
            </button>
          </div>
        </form>
        
      </div>
  )
}
