import React from 'react'
import { RoofCalculator } from '../pages/roof-calculator/RoofCalculator'

export default function Base() {
  return (
    <div>
        <div className="flex flex-col bg-white">
            <RoofCalculator/>
        </div>
    </div>
  )
}
