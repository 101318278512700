import React, { useState, useEffect, useRef } from 'react';
import { useInput } from '../InputProvider';
import axios from 'axios';
import * as geotiff from 'geotiff';
import * as geokeysToProj4 from 'geotiff-geokeys-to-proj4';
import proj4 from 'proj4';
import { MoreAccuracyComponent } from './MoreAccuracyComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export function Step5Component({ onNext, onBack }) {
	const autocompleteInputRef = useRef(null);
	const mapContainerRef = useRef(null);
	const [showMap, setShowMap] = useState(true);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [markerPosition, setMarkerPosition] = useState(null); // Store marker position
	const [loading, setLoading] = useState(false); // State to track loading status
	const { setAreaOfRoof, address, setAddress, setMainMap, mainMap, setLatLng, setSolarApi, setCanvasImage, setBounds } = useInput();
	const googleMapsApiKey = 'AIzaSyCGWOqd-SGYjoDuqVvTcns7OAqJkaHMmfo';
	const binaryPalette = ['ffffffff', '00000000'];
	const [error, setError] = useState(false); // State for error
	const [reqError, setReqError] = useState(false);	// required error
	const [process, setProcess] = useState(false);	// processing the map
    const [currentStep, setCurrentStep] = useState(5);

	useEffect(() => {
		setAddress(address);

		// Get all the div elements
		const allDivs = document.querySelectorAll('div');

		// Iterate through the div elements
		allDivs.forEach((div) => {
			// Check if the current div has only one child div
			if (div.children.length === 1 && div.children[0].classList.contains('items-center', 'justify-center', 'w-full', 'map-container-custom')) {
				// Apply the styles to the outer div
				div.style.width = '100%';
			}
		});

	}, [setAddress, address]);

	const handleInputChange = (event) => {
		const value = event.target.value;
		setAddress(value);

		// Validate address input
		if (!value.trim()) {
			setReqError(true);
		} else {
			setReqError(false);
			setError(false);
		}
	};

	const handleNext = () => {

		if (error) {
			onNext();
		}

		if (address.trim() !== '') {
			setLoading(true);
			setReqError(false);
			// Take a screenshot and save it
			captureAndSaveScreenshot();

		} else {
			setReqError(true);
		}
	};

	// Function to handle when the map finishes loading
	const handleMapLoad = () => {
		setMapLoaded(true);
	};

	const captureAndSaveScreenshot = () => {

		if (!markerPosition) {
			console.error('Marker position not found');
			return;
		}

		const [lat, lng] = markerPosition.split(',');
		const zoomLevel = 20;

		// store lat and lng in state
		setLatLng({ lat, lng });

		const solarApiUrl = `https://solar.googleapis.com/v1/buildingInsights:findClosest?location.latitude=${lat}&location.longitude=${lng}&requiredQuality=HIGH&key=AIzaSyCGWOqd-SGYjoDuqVvTcns7OAqJkaHMmfo`;

		axios.get(solarApiUrl)
			.then(response => {
				const data = response.data;
				setSolarApi(data);

				var center = new window.google.maps.LatLng(data.center.latitude, data.center.longitude);

				const mapOptions = {
					center: center,
					zoom: 20,
					mapTypeId: "satellite",
					tilt: 0,
					mapTypeControl: false,
					streetViewControl: false,
				};

				const mapInstance = new window.google.maps.Map(
					mapContainerRef.current,
					mapOptions
				);
				console.log(data);
				if (data && data.solarPotential) {
					const areaMeters2 = data.solarPotential.wholeRoofStats.areaMeters2; // Assuming this field exists

					var totalArea = 0;
					var totalPitchedArea = 0;
					var totalGroundArea = 0;
					data.solarPotential.roofSegmentStats.forEach(function (roofSegmentStat) {
						const solarCoords = roofSegmentStat.boundingBox;
						const solarLatLng = [
							{ lat: solarCoords.ne.latitude, lng: solarCoords.ne.longitude },
							{ lat: solarCoords.ne.latitude, lng: solarCoords.sw.longitude },
							{ lat: solarCoords.sw.latitude, lng: solarCoords.sw.longitude },
							{ lat: solarCoords.sw.latitude, lng: solarCoords.ne.longitude }
						];
						//7740 Silver Lake Rd, St Paul, MN 55112, USA
						const solarPolygon = new window.google.maps.Polygon({
							paths: solarLatLng,
							strokeColor: '#00FF00',
							strokeOpacity: 0,
							strokeWeight: 0,
							fillColor: '#00FF00',
							fillOpacity: 0.02
						});
						solarPolygon.setMap(mapInstance);
						totalArea += roofSegmentStat.stats.areaMeters2;
						console.log("roofSegmentStat.pitchDegrees", roofSegmentStat.pitchDegrees)
						totalPitchedArea += calculateRoofArea(roofSegmentStat.stats.areaMeters2, roofSegmentStat.pitchDegrees);
						totalGroundArea += calculateRoofArea(roofSegmentStat.stats.groundAreaMeters2, roofSegmentStat.pitchDegrees);
					});
					console.log("totalArea", totalArea);
					console.log("totalPitchedArea", totalPitchedArea);
					console.log("totalGroundArea", totalGroundArea);
					setProcess(false); // processing finish

					fetchGeoTiffData(lat, lng, googleMapsApiKey).then(async response => {
						console.log('geoTiff', response);

						// Get data from response in JSON
						const data1 = await response.json();
						console.log('data', data1);

						// Download GeoTIFF data
						const [mask, image, dsm] = await Promise.all([
							downloadGeoTIFF(data1.maskUrl, googleMapsApiKey),
							downloadGeoTIFF(data1.rgbUrl, googleMapsApiKey),
							downloadGeoTIFF(data1.dsmUrl, googleMapsApiKey),
						]);
						console.log('mask', mask);
						console.log('image', image);
						console.log('dsm', dsm);

						const colors = binaryPalette;

						// create the canvas with mask data
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						canvas.width = dsm.width;
						canvas.height = dsm.height;

						// Create image from the first GeoTIFF data
						const imageData1 = ctx.createImageData(dsm.width, dsm.height);
						const dataLength1 = dsm.width * dsm.height;

						// Find the minimum and maximum values in dsm.rasters[0]
						let minVal = Infinity;
						let maxVal = -Infinity;

						for (let i = 0; i < dataLength1; i++) {
							if (dsm.rasters[0][i] < minVal) {
								minVal = dsm.rasters[0][i];
							}
							if (dsm.rasters[0][i] > maxVal) {
								maxVal = dsm.rasters[0][i];
							}
						}

						for (let i = 0; i < dataLength1; i++) {
							const index = i * 4;
							const normalizedValue = ((dsm.rasters[0][i] - minVal) / (maxVal - minVal)) * 255;
							/*imageData1.data[index] = dsm.rasters[0][i];
							imageData1.data[index + 1] = dsm.rasters[1][i];
							imageData1.data[index + 2] = dsm.rasters[2][i];
							imageData1.data[index + 3] = 255;*/
							imageData1.data[index] = normalizedValue;
							imageData1.data[index + 1] = normalizedValue;
							imageData1.data[index + 2] = normalizedValue;
							imageData1.data[index + 3] = 255;
						}
						ctx.putImageData(imageData1, 0, 0);

						// Create image from the mask GeoTIFF data
						const imageData2 = ctx.createImageData(mask.width, mask.height);
						const dataLength = mask.width * mask.height;

						for (let i = 0; i < dataLength; i++) {
							const color = colors[mask.rasters[0][i]];
							const index = i * 4;
							imageData2.data[index] = parseInt(color.substring(0, 2), 16);
							imageData2.data[index + 1] = parseInt(color.substring(2, 4), 16);
							imageData2.data[index + 2] = parseInt(color.substring(4, 6), 16);
							imageData2.data[index + 3] = parseInt(color.substring(6, 8), 16);
						}

						// Create image from the dsm GeoTIFF data
						// Get the existing canvas data
						var canvasData = ctx.getImageData(0, 0, dsm.width, dsm.height);

						// Blend the second ImageData with the existing canvas data
						for (var i = 0; i < imageData2.data.length; i += 4) {
							var alpha = imageData2.data[i + 3] / 255;
							canvasData.data[i] = alpha * imageData2.data[i] + (1 - alpha) * canvasData.data[i];
							canvasData.data[i + 1] = alpha * imageData2.data[i + 1] + (1 - alpha) * canvasData.data[i + 1];
							canvasData.data[i + 2] = alpha * imageData2.data[i + 2] + (1 - alpha) * canvasData.data[i + 2];
							// Preserve the original alpha value
							canvasData.data[i + 3] = 255;
						}

						// Put the blended ImageData back onto the canvas
						ctx.putImageData(canvasData, 0, 0);
						//set the image to canvasImage

						//set map ground overlay
						const imageBounds = {
							north: image.bounds.north,
							south: image.bounds.south,
							east: image.bounds.east,
							west: image.bounds.west,
						};

						setBounds(imageBounds)
						setCanvasImage(canvas);

						const overlay = new window.google.maps.GroundOverlay(
							canvas.toDataURL(),
							imageBounds
						);
						
						onNext();
						setCurrentStep(currentStep + 1); // Move to the next step
					});


					//const areaParts = areaString.split(' ');
					const areaValueSqm = totalPitchedArea //parseFloat(areaMeters2) * 0.464515;
					//convert to square feet
					const areaValueSqft = areaValueSqm * 10.7639;
					const areaValueSqftRounded = Math.round(areaValueSqft);
					setAreaOfRoof(areaValueSqftRounded);
					setMainMap(mapInstance);
					//onNext(); // Move to next step
				} else {
					console.error('No roof data available for this address.');
				}
			})
			.catch(error => {
				console.error('Failed to fetch data from Google Solar API.', error);
				setError(true); // Set the error state
				setCurrentStep(currentStep); // Move to the next step
			})
			.finally(() => {
				setLoading(false); // Set loading to false
			});
	};

	// Fetch GeoTIFF data for the given location
	async function fetchGeoTiffData(latitude, longitude, apiKey) {
		setProcess(true); // processing...

		const url = `https://solar.googleapis.com/v1/dataLayers:get?requiredQuality=LOW&location.latitude=${latitude}&location.longitude=${longitude}&requiredQuality=LOW&radiusMeters=30&key=${apiKey}`;
		//data is not json it is base64
		const response = await fetch(url);

		return response;
	}

	async function downloadGeoTIFF(url, apiKey) {

		console.log(`Downloading data layer: ${url}`);

		// Include your Google Cloud API key in the Data Layers URL.
		const solarUrl = url.includes('solar.googleapis.com') ? url + `&key=${apiKey}` : url;
		const response = await fetch(solarUrl);
		if (response.status != 200) {
			const error = await response.json();
			console.error(`downloadGeoTIFF failed: ${url}\n`, error);
			throw error;
		}

		// Get the GeoTIFF rasters, which are the pixel values for each band.
		const arrayBuffer = await response.arrayBuffer();
		const tiff = await geotiff.fromArrayBuffer(arrayBuffer);
		const image = await tiff.getImage();
		const rasters = await image.readRasters();

		// Reproject the bounding box into lat/lon coordinates.
		const geoKeys = image.getGeoKeys();
		const projObj = geokeysToProj4.toProj4(geoKeys);
		const projection = proj4(projObj.proj4, 'WGS84');
		const box = image.getBoundingBox();
		const sw = projection.forward({
			x: box[0] * projObj.coordinatesConversionParameters.x,
			y: box[1] * projObj.coordinatesConversionParameters.y,
		});
		const ne = projection.forward({
			x: box[2] * projObj.coordinatesConversionParameters.x,
			y: box[3] * projObj.coordinatesConversionParameters.y,
		});

		return {
			// Width and height of the data layer image in pixels.
			// Used to know the row and column since Javascript
			// stores the values as flat arrays.
			width: rasters.width,
			height: rasters.height,
			// Each raster reprents the pixel values of each band.
			// We convert them from `geotiff.TypedArray`s into plain
			// Javascript arrays to make them easier to process.
			rasters: Array.from({ length: rasters.length }, (v, i) =>
				Array.from(rasters[i])
			),
			// The bounding box as a lat/lon rectangle.
			bounds: {
				north: ne.y,
				south: sw.y,
				east: ne.x,
				west: sw.x,
			},
		};
	}

	function calculateRoofArea(baseArea, pitchDegrees) {
		// Convert pitch from degrees to radians
		const pitchRadians = pitchDegrees * (Math.PI / 180);

		// Calculate the secant of the pitch angle
		const secant = 1 / Math.cos(pitchRadians);

		// Calculate the roof surface area
		const roofSurfaceArea = baseArea * secant;

		return roofSurfaceArea;
	}
	// Autocomplete 
	useEffect(() => {
		const google = window.google;
		if (!google || !google.maps) {
			console.error("Google Maps API not loaded");
			return;
		}

		const autocomplete = new google.maps.places.Autocomplete(
			autocompleteInputRef.current
		);

		autocomplete.addListener("place_changed", () => {
			const place = autocomplete.getPlace();
			console.log('place', place);
			console.log('place.geometry.location', place.geometry.location);
			if (!place.geometry) {
				console.error(
					"No place data available for input:",
					autocompleteInputRef.current.value
				);
				return;
			}

			setAddress(place.formatted_address);
			setShowMap(false);

			const mapOptions = {
				center: place.geometry.location,
				zoom: 20,
				mapTypeId: "satellite",
				tilt: 0,
				mapTypeControl: false,
				streetViewControl: false,
			};

			const mapInstance = new google.maps.Map(
				mapContainerRef.current,
				mapOptions
			);

			const marker = new google.maps.Marker({
				position: place.geometry.location,
				map: mapInstance,
				title: place.name,
				draggable: true,
			});


			// Update marker position when it's dragged
			marker.addListener('dragend', () => {
				const newPosition = marker.getPosition();
				setMarkerPosition(`${newPosition.lat()},${newPosition.lng()}`);
			});


			// Set initial marker position
			setMarkerPosition(`${place.geometry.location.lat()},${place.geometry.location.lng()}`);
		});
	}, []);

	// Function to handle going back to the previous step
    const handleBack = () => {
        console.log("back");
        setError(false);
        setCurrentStep(currentStep - 2); // Go back to the previous step
    };

	return (
		<div className='items-center justify-center w-full map-container-custom'>
			<div className={`relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl ${error ? 'hidden' : ''}`}>
				Roofing Calculator
			</div>
			<div className={`text-sm mb-2 mt-5 font-bold text-center text-dark uppercase tracking-[1.6px] ${error ? 'hidden' : ''}`}>
				Move the marker to the center of the roof
			</div>
			<div className={`${error ? 'hidden' : ''}`}>
				<div>
					<input
						onChange={handleInputChange}
						value={address}
						className='input-location style1-input relative mt-10 text-sm whitespace-nowrap max-sm:mt-10 w-[280px]'
						type='text'
						ref={autocompleteInputRef}
						placeholder='Search your address here'
					/>

					{reqError && (
						<div className="items-center text-red-600 text-sm mt-3">
							<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
							<span>Address is required</span>
						</div>
					)}
					{error && (
						<div className="items-center text-red-600 text-sm mt-3">
							<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
							<span>Please search a valid location</span>
						</div>
					)}
					{process && (
						<div className="items-center text-sm mt-3">
							<span>Calculating the area...</span>
						</div>
					)}
					{!reqError && !error && !process && (
						<br />
					)}

				</div>
				<br />
				<div className={`flex overflow-hidden relative flex-col justify-center ${showMap ? 'py-10' : 'py-3'} mt-0 w-full max-w-100 mx-auto`}>
					{!mapLoaded && (
						<div className="absolute inset-0 flex items-center justify-center opacity-75 z-10">
							<p className='text-3xl text-center'>Loading map...</p>
						</div>
					)}
					{showMap &&
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d571931.956837237!2d-86.83934581788408!3d30.280758883306262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8891450ab4ed366b%3A0x3e2955667c0c8706!2sRoofPro%20Roofing%20-%20Destin%2C%20Florida!5e0!3m2!1sen!2slk!4v1722877004709!5m2!1sen!2slk"
							title="map"
							width="100%"
							height="350px"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
							style={{ border: '0' }}
							onLoad={handleMapLoad}
						>
						</iframe>
					}
				</div>

				{loading &&
					<div className={`flex overflow-hidden relative flex-col justify-center items-center px-16 mb-5 mt-0 w-full max-w-[1500px] mx-auto`} style={{ fontSize: '18px' }}>
						<p>Please wait a moment...</p>
					</div>
				}

				<div className={`${showMap || loading ? 'hidden' : ''} zoom-container flex overflow-hidden relative flex-col justify-center items-center mb-5 mt-0 w-full max-w-[1500px] mx-auto`}>
					<div
						ref={mapContainerRef}
						className="map"
						style={{ height: '450px', width: '100%' }}
					>
					</div>
				</div>

				<div className='sm:pr-3'>
					<button
						onClick={onBack}
						className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
						Back
					</button>
					<button
						onClick={handleNext}
						disabled={process}
						className={`main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer`}>
						Next
					</button>
				</div>
			</div>

			{error && <MoreAccuracyComponent onNext={handleNext} onBack={handleBack} currentStep={currentStep} />}

		</div>
	)
}
