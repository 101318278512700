import React, { useState, useEffect, useRef } from 'react'
import { useInput } from '../InputProvider';
import * as geotiff from 'geotiff';
import { MoreAccuracyComponent } from './MoreAccuracyComponent';

export function Step6Component({ onNext, onBack }) {
	const mapContainerRef = useRef(null);
	const [showMap, setShowMap] = useState(true);
    const [mapLoaded, setMapLoaded] = useState(false);
	const [loading, setLoading] = useState(false); // State to track loading status
    const {mainMap, areaOfRoof, solarApi, canvasImage, bounds} = useInput();
    const [showMoreAccuracy, setShowMoreAccuracy] = useState(false); // State to control visibility of MoreAccuracyComponent
    const [currentStep, setCurrentStep] = useState(6);
    
    useEffect(() => {

		if (!solarApi) {
			console.error('No solar API data available.');
			return;
		}
        setMapLoaded(true);	
		setShowMap(true);
		var data = solarApi;
		var center = new window.google.maps.LatLng(data.center.latitude, data.center.longitude);

		const mapOptions = {
			center: center,
			zoom: 20,
			mapTypeId: "satellite",
			tilt: 0,
			mapTypeControl: false,
			streetViewControl: false,
		  };

		const mapInstance = new window.google.maps.Map(
			mapContainerRef.current,
			mapOptions
		  );
        console.log(data);
        if (data && data.solarPotential) {
				const areaMeters2 = data.solarPotential.wholeRoofStats.areaMeters2; // Assuming this field exists

				var totalArea = 0;
				var totalPitchedArea = 0;
				var totalGroundArea = 0;
				data.solarPotential.roofSegmentStats.forEach(function(roofSegmentStat){
				const solarCoords = roofSegmentStat.boundingBox;
				const solarLatLng = [
					{ lat: solarCoords.ne.latitude, lng: solarCoords.ne.longitude },
					{ lat: solarCoords.ne.latitude, lng: solarCoords.sw.longitude },
					{ lat: solarCoords.sw.latitude, lng: solarCoords.sw.longitude },
					{ lat: solarCoords.sw.latitude, lng: solarCoords.ne.longitude }
				];
				//7740 Silver Lake Rd, St Paul, MN 55112, USA
				const solarPolygon = new window.google.maps.Polygon({
					paths: solarLatLng,
					strokeColor: '#00FF00',
					strokeOpacity: 0,
					strokeWeight: 0,
					fillColor: '#00FF00',
					fillOpacity: 0.002
				});
				solarPolygon.setMap(mapInstance);
				totalArea += roofSegmentStat.stats.areaMeters2;
				totalPitchedArea += calculateRoofArea(roofSegmentStat.stats.areaMeters2, roofSegmentStat.pitchDegrees);
				totalGroundArea += calculateRoofArea(roofSegmentStat.stats.groundAreaMeters2, roofSegmentStat.pitchDegrees);
			});
			console.log("totalArea", totalArea);
			console.log("totalPitchedArea", totalPitchedArea);
			console.log("totalGroundArea", totalGroundArea);    

			//const areaParts = areaString.split(' ');
			const areaValueSqm  = totalPitchedArea //parseFloat(areaMeters2) * 0.464515;
			//convert to square feet
			const areaValueSqft = areaValueSqm * 10.7639;
			const areaValueSqftRounded = Math.round(areaValueSqft);

			const overlay = new window.google.maps.GroundOverlay(
				canvasImage.toDataURL(),
				bounds
			);
			overlay.setMap(mapInstance);

			//setAreaOfRoof(areaValueSqftRounded);
			//setMainMap(mapInstance);
			//onNext(); // Move to next step
        } else {
          console.error('No roof data available for this address.');
        }
        console.log("awaa", mapInstance);
    }, []);

    // Function to handle click on "Do you want more accuracy?" button
    const handleMoreAccuracyClick = () => {
        setShowMoreAccuracy(true); // Set state to true to show MoreAccuracyComponent
    };

    const handleNext = () => {
        console.log("awa..");
        if (showMoreAccuracy) {
            setShowMoreAccuracy(false);
        }

		//load setMap variable on to mapContainerRef
		
		console.log("currentStepPre : " + currentStep);
        setCurrentStep(currentStep + 1); // Move to the next step
		console.log("currentStep : " + currentStep);
    };

	function calculateRoofArea(baseArea, pitchDegrees) {
	  // Convert pitch from degrees to radians
	  const pitchRadians = pitchDegrees * (Math.PI / 180);
  
	  // Calculate the secant of the pitch angle
	  const secant = 1 / Math.cos(pitchRadians);
  
	  // Calculate the roof surface area
	  const roofSurfaceArea = baseArea * secant;
  
	  return roofSurfaceArea;
	}

    // Function to handle going back to the previous step
    const handleBack = () => {
        console.log("next");
        setShowMoreAccuracy(false);
        setCurrentStep(currentStep - 1); // Go back to the previous step
    };

    return (
        <div className='items-center justify-center'>
            <div className={`${showMoreAccuracy ? 'hidden' : ''} relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl`}>
                Roofing Calculator
            </div>

            <div className={`self-stretch mt-9 max-sm:max-w-full ${showMoreAccuracy ? 'hidden' : ''}`}>
                <div className="flex gap-5 max-sm:flex-col max-sm:gap-0">
                    <div className={`flex flex-col w-[62%] max-sm:ml-0 max-sm:w-full ${!showMoreAccuracy ? 'hidden' : ''}`}>
						<div className={`${showMap || loading ? '' : ''} zoom-container flex overflow-hidden relative flex-col justify-center items-center mb-5 mt-0 w-full max-w-[1500px] mx-auto`}>
							<div 
							ref={mapContainerRef} 
							className="map"
							style={{ height: '450px', width: '100%' }}
							>
							</div>
						</div>
                    </div>
                    <div className={`area-show flex flex-col ml-5 mr-5 ${!showMoreAccuracy ? 'w-[100%]' : 'w-[38%]'}  max-sm:ml-0 max-sm:w-full`}>
                        <div className="flex flex-col grow px-12 pt-8 pb-5 text-sm font-bold tracking-tighter border-cyan-600 border-solid text-stone-900 max-sm:px-5 max-sm:mt-7">
                            <div className='uppercase text-white'>Your roof area</div>
                            <div className="self-center mt-3.5 text-lg tracking-tighter text-center">
                                <span className="font-extrabold text-theme-color">{areaOfRoof.toLocaleString()}</span>
                                <span className="font-extrabold text-theme-color"> Ft<sup>2</sup></span>
                            </div>
                            <div className="self-center uppercase mt-3 text-white">Continue?</div>
                            <div className="mt-5 text-sm tracking-tight leading-5 text-center underline max-sm:mt-5">
                                <button
                                    className='more-accuracy text-white text-underline'
                                    onClick={handleMoreAccuracyClick}
                                >
                                    Do you want more accuracy?
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showMoreAccuracy && 
				<MoreAccuracyComponent 
					onNext={handleNext} 
					onBack={handleBack} 
					currentStep={currentStep}
				/>
			}

            <div className={`sm:pr-3 ${showMoreAccuracy ? 'hidden' : ''}`}>
                <button
                    onClick={onBack}
                    className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
                    Back
                </button>
                <button
                    onClick={onNext}
                    className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
                    Next
                </button>
            </div>

        </div>
    )
}
