import React from 'react';

export function ProgressBarComponent({ currentStep }) {

    const steps = [
        "Gather requirements",
        "Personal information",
        "Contact method",
        "Appointment",
        "Get Estimate!"
    ];

    return (
        <>
            {/* Large Screens */}
            <div className="sm:flex relative justify-center gap-5 mt-0 max-w-full text-base text-center w-[789px] max-md:flex-wrap max-md:mt-10 mx-auto hide-on-mobile">
                <div className="flex flex-auto gap-5 justify-between">
                    <div className="flex relative justify-center gap-5 mt-16 w-full max-w-full text-base text-xs text-center max-md:flex-wrap max-md:mt-10 mx-auto">
                        {steps.map((step, index) => (
                            <div className="flex flex-col flex-1 self-start whitespace-nowrap" key={index}>
                                {/* Highlight the current step */}
                                <div className={`shrink-0 self-center w-5 h-5 rounded-full border border-cyan-600 border-solid 
                        ${(currentStep === 1 && index === 0)
                                        || (currentStep === 2 && index === 0)
                                        || (currentStep === 3 && index === 0)
                                        || (currentStep === 4 && index === 0)
                                        || (currentStep === 5 && (index === 0 || index === 1))
                                        || (currentStep === 6 && (index === 0 || index === 1))
                                        || (currentStep === 7 && (index === 0 || index === 1 || index === 2))
                                        || (currentStep === 8 && (index === 0 || index === 1 || index === 2 || index === 3))
                                        || (currentStep === 9 && (index === 0 || index === 1 || index === 2 || index === 3 || index === 4))
                                        ? 'bg-cyan-600' : 'bg-stone-200'}`}
                                    style={{ zIndex: 2 }} />
                                {/* Display the step text */}
                                <div className={`mt-3`}>{step}</div>
                            </div>
                        ))}
                        {/* Progress Line */}
                        <div className="absolute top-0 left-14 right-10 mt-2.5" style={{ zIndex: 1 }}>
                            <div className="h-[1px] bg-cyan-600" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Small Screens */}
            <div className="sm:hidden flex flex-col text-sm items-start mt-10 hide-on-mobile">
                {steps.map((step, index) => (
                    <div className="flex items-center" key={index}>
                        {/* Highlight the current step */}
                        <div className={`shrink-0 self-center w-4 h-4 rounded-full border mt-5 mr-3 border-cyan-600 border-solid 
                ${(currentStep === 1 && index === 0)
                                || (currentStep === 2 && index === 0)
                                || (currentStep === 3 && index === 0)
                                || (currentStep === 4 && index === 0)
                                || (currentStep === 5 && (index === 0 || index === 1))
                                || (currentStep === 6 && (index === 0 || index === 1))
                                || (currentStep === 7 && (index === 0 || index === 1 || index === 2))
                                || (currentStep === 8 && (index === 0 || index === 1 || index === 2 || index === 3))
                                || (currentStep === 9 && (index === 0 || index === 1 || index === 2 || index === 3 || index === 4))
                                ? 'bg-cyan-600' : 'bg-stone-200'}`}
                            style={{ zIndex: 2 }} />
                        {/* Step Text */}
                        <div className={`mt-5`}>{step}</div>
                    </div>
                ))}
            </div>


        </>
    );
}
