// MoreAccuracyComponent.js

import React, { useState, useEffect, useRef } from 'react';
import { useInput } from '../InputProvider';
import { Step6Component } from './Step6Component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Step5Component } from './Step5Component';

export function MoreAccuracyComponent({ onNext, onBack, currentStep }) {
  const autocompleteInputRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [showMap, setShowMap] = useState(true);

  const [map, setMap] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [drawnPolygon, setDrawnPolygon] = useState(null);

  const [error, setError] = useState(false); // State for error
  const [reqError, setReqError] = useState(false);	// required error
  const [process, setProcess] = useState(false);	// processing the map

  const [mapLoaded, setMapLoaded] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading status
  const { setAreaOfRoof, areaOfRoof, address, setAddress, setCapturedImage, setLatLng } = useInput();

  const [showStep5, setShowStep5] = useState(false);
  const [showStep6, setShowStep6] = useState(false);

  useEffect(() => {
    setAddress(address);

    // (UI CODE)
    // Get all the div elements
    const allDivs = document.querySelectorAll('div');

    // Iterate through the div elements 
    allDivs.forEach((div) => {
      // Check if the current div has only one child div
      if (div.children.length === 1 && div.children[0].classList.contains('items-center', 'justify-center', 'w-full', 'map-container-custom')) {
        // Apply the styles to the outer div
        div.style.width = '100%';
      }
    });

  }, [setAddress, address]);


  useEffect(() => {
    const google = window.google;
    if (!google || !google.maps) {
      console.error("Google Maps API not loaded");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      autocompleteInputRef.current
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error(
          "No place data available for input:",
          autocompleteInputRef.current.value
        );
        return;
      }

      setAddress(place.formatted_address);
      setShowMap(false);

      const mapOptions = {
        center: place.geometry.location,
        zoom: 20,
        mapTypeId: "satellite",
        tilt: 0,
        mapTypeControl: false,
        streetViewControl: false,
      };

      const mapInstance = new google.maps.Map(
        mapContainerRef.current,
        mapOptions
      );

      new google.maps.Marker({
        position: place.geometry.location,
        map: mapInstance,
        title: place.name,
      });

      setMap(mapInstance);

      const drawingManagerInstance = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
      });

      drawingManagerInstance.setMap(mapInstance);
      setDrawingManager(drawingManagerInstance);

      google.maps.event.addListener(
        drawingManagerInstance,
        "overlaycomplete",
        (event) => {
          if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            const polygon = event.overlay;
            setDrawnPolygon(polygon);
          }
        }
      );
    });
  }, []);

  const resetMap = () => {
    if (drawnPolygon) {
      drawnPolygon.setPath([]);
      setDrawnPolygon(null);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setAddress(value);

    // Validate address input
    if (!value.trim()) {
      setReqError(true);
    } else {
      setReqError(false);
	  setError(false);
    }
  };

  const handleNext = () => {
    if (address.trim() !== '') {
      setLoading(true);
      setReqError(false);
      // Take a screenshot and save it
      captureAndSaveScreenshot();
    } else {
      setReqError(true);
    }
  };

  // Function to handle when the map finishes loading
  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const captureAndSaveScreenshot = () => {
    if (!drawnPolygon) {
      console.error('No polygon drawn');
      return;
    }

    const path = drawnPolygon.getPath().getArray();

    // Convert path coordinates to encoded polyline format
    const encodedPath = window.google.maps.geometry.encoding.encodePath(path);

    // Construct the static map URL with the encoded polyline
    const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&path=enc:${encodedPath}&maptype=satellite&key=AIzaSyCGWOqd-SGYjoDuqVvTcns7OAqJkaHMmfo`;

    // Save the captured image URL
    setCapturedImage(staticMapUrl);
    setLoading(false);

    console.log(currentStep);

    if(currentStep == 6){
      setShowStep6(true);
    } else if(currentStep == 5){
      setShowStep5(true);
    }
  };

  const calculatePrice = () => {

    if (drawnPolygon) {
      const path = drawnPolygon.getPath();
      const areaInSquareMeters =
        window.google.maps.geometry.spherical.computeArea(path);
      const areaInSquareFeet = areaInSquareMeters * 10.7639;
      setAreaOfRoof(areaInSquareFeet)

      handleNext();

    } else {
      alert("No roof outline was drawn to calculate the pricing.");
    }
  };

  return (
    <div className='items-center justify-center w-full map-container-custom'>
      <div className={`${showStep6 || showStep5 ? 'hidden' : ''} relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl`}>
        Roofing Calculator
      </div>
      <div className={`text-sm mb-2 mt-5 font-bold text-center text-dark uppercase tracking-[1.6px] ${showStep6 || showStep5 ? 'hidden' : ''}`}>
        Draw on your roof
      </div>
      <div className={`${showStep6 || showStep5 ? 'hidden' : ''}`}>
        <input 
          onChange={handleInputChange}
          style={{ marginBottom: '6px' }}
          required
          className='style1-input input-location relative mt-10 text-sm  whitespace-nowrap max-sm:mt-10 w-[350px]' 
          type='text' 
          ref={autocompleteInputRef}
          placeholder='Search your address here'
        />
         <br/>
        <small style={{ fontSize: '15px' }}>(Please search the location and draw the roof area)</small>
        <br/>
        {reqError && (
          <div className="items-center text-red-600 text-sm mt-3">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>Address is required</span>
          </div>
        )}
        {error && (
          <div className="items-center text-red-600 text-sm mt-3">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>Please search a valid location</span>
          </div>
        )}
        {!reqError && !error && !process && (
          <br/>
        )}

      </div>
      <br/>
      <div className={`flex overflow-hidden relative flex-col justify-center ${showMap ? 'py-10' : 'py-3'} ${showStep6 || showStep5 ? 'hidden' : ''} mt-0 w-full max-w-[1500px] mx-auto zoom-container`}>
        {!mapLoaded && (
          <div className="absolute inset-0 flex items-center justify-center opacity-75 z-10">
            <p className='text-3xl text-center'>Loading map...</p>
          </div>
        )}
        {showMap &&
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d571931.956837237!2d-86.83934581788408!3d30.280758883306262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8891450ab4ed366b%3A0x3e2955667c0c8706!2sRoofPro%20Roofing%20-%20Destin%2C%20Florida!5e0!3m2!1sen!2slk!4v1722877004709!5m2!1sen!2slk"
          title="map"
          width="100%"
          height="350px"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ border: '0' }}
          onLoad={handleMapLoad}
        >
        </iframe>
        }
      </div>

      {loading &&
        <div className={`${showStep6 || showStep5 ? 'hidden' : ''} flex overflow-hidden relative flex-col justify-center items-center px-16 mb-5 mt-0 w-full max-w-[1500px] mx-auto`} style={{ fontSize: '24px'}}>
          <p>Please wait a moment...</p>
        </div>
      }

      <div className={`${showMap || loading ? 'hidden' : ''} ${showStep6 || showStep5 ? 'hidden' : ''} flex overflow-hidden relative flex-col justify-center items-center mb-5 mt-0 w-full max-w-[1500px] mx-auto`}>
        <div 
          ref={mapContainerRef} 
          className="map"
          style={{ height: '450px', width: '100%' }}
          >
        </div>
      </div>
     
      <div className={`sm:pr-3 ${showStep6 || showStep5 ? 'hidden' : ''}`}>
        <button
          onClick={() => {
            // Call onBack function here
            onBack();
            // Hide Step6Component & Step5Component
            if(currentStep == 6){
              setShowStep6(false);
            } else if(currentStep == 5){
              setShowStep5(false);
            }
          }}
          className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
          Back
        </button>
        <button
          onClick={calculatePrice}
          className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
          Next
        </button>
      </div>
      {(showStep6 || showStep5) && <Step6Component onNext={onNext} onBack={onBack} />}

    </div>
  )
}
