import logo from './logo.svg';
import './App.css';
import Base from './components/container/Base';

function App() {
  return (
    <div className="App">
      <Base/>
    </div>
  );
}

export default App;
