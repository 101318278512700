import React, { createContext, useState, useContext } from 'react';

const InputContext = createContext();

export function InputProvider({ children }) {
  const [zipCode, setZipCode] = useState('');
  const [roofingProject, setRoofingProject] = useState('');
  const [roofingProjectPrice, setRoofingProjectPrice] = useState(0);
  const [roofingMaterial, setRoofingMaterial] = useState('');
  const [roofingMaterialPrice, setRoofingMaterialPrice] = useState(0);
  const [buildingStories, setBuildingStories] = useState('');
  const [buildingStoriesPrice, setBuildingStoriesPrice] = useState(0);
  const [address, setAddress] = useState('');
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [solarApi, setSolarApi] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const [mainMap, setMainMap] = useState(null); //
  const [areaOfRoof, setAreaOfRoof] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [quoteMethod, setQuoteMethod] = useState('');
  const [priceCount, setPriceCount] = useState('');
  const [appointmentOneDate, setAppointmentOneDate] = useState('');
  const [appointmentOneTime, setAppointmentOneTime] = useState('');
  const [appointmentTwoDate, setAppointmentTwoDate] = useState('');
  const [appointmentTwoTime, setAppointmentTwoTime] = useState('');
  const [canvasImage, setCanvasImage] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [lowRangeCost, setLowRangeCost] = useState(0);
  const [midRangeCost, setMidRangeCost] = useState(0);
  const [highRangeCost, setHighRangeCost] = useState(0);

  const logAllValues = () => {
    console.log('Zip Code:', zipCode);
    console.log('Roofing Project:', roofingProject + ' - ' + roofingProjectPrice);
    console.log('Roofing Material:', roofingMaterial + ' - ' + roofingMaterialPrice);
    console.log('Building Stories:', buildingStories + ' - ' + buildingStoriesPrice);
    console.log('Address:', address);
    console.log('Latitude:', latLng.lat);
    console.log('Area of roof:', areaOfRoof);
    console.log('First Name:', firstname);
    console.log('Last Name:', lastname);
    console.log('Email:', email);
    console.log('Phone:', phone);
    console.log('Verification Code:', verificationCode);
    console.log('Quote Method:', quoteMethod);
    console.log('Price Count:', priceCount);
    console.log('Appointment One Date:', appointmentOneDate);
    console.log('Appointment One Time:', appointmentOneTime);
    console.log('Appointment Two Date:', appointmentTwoDate);
    console.log('Appointment Two Time:', appointmentTwoTime);
  };

  return (
    <InputContext.Provider
      value={{
        zipCode,
        setZipCode,
        roofingProject,
        setRoofingProject,
        roofingProjectPrice,
        setRoofingProjectPrice,
        roofingMaterial,
        setRoofingMaterial,
        roofingMaterialPrice,
        setRoofingMaterialPrice,
        buildingStories,
        setBuildingStories,
        buildingStoriesPrice,
        setBuildingStoriesPrice,
        address,
        setAddress,
        latLng,
        setLatLng,
        solarApi,
        setSolarApi,
        areaOfRoof,
        setAreaOfRoof,
        capturedImage,
        setCapturedImage,
        firstname,
        setFirstname,
        lastname,
        setLastname,
        email,
        setEmail,
        phone,
        setPhone,
        verificationCode,
        setVerificationCode,
        quoteMethod,
        setQuoteMethod,
        priceCount,
        setPriceCount,
        appointmentOneDate,
        setAppointmentOneDate,
        appointmentOneTime,
        setAppointmentOneTime,
        appointmentTwoDate,
        setAppointmentTwoDate,
        appointmentTwoTime,
        setAppointmentTwoTime,
        logAllValues,
		mainMap,
		setMainMap,
		canvasImage,
		setCanvasImage,
		bounds,
		setBounds,
		lowRangeCost,
		setLowRangeCost,
		midRangeCost,
		setMidRangeCost,
		highRangeCost,
		setHighRangeCost
      }}
    >
      {children}
    </InputContext.Provider>
  );
}

export function useInput() {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error('useInput must be used within an InputProvider');
  }
  return context;
}
