import React, { useEffect } from 'react'
import { useInput } from '../InputProvider'
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export function Step8Component({ onNext, onBack }) {

  // inputprovider stored values
  const {
    zipCode,
    roofingProjectPrice,
    roofingMaterialPrice,
    buildingStoriesPrice,
    areaOfRoof,
    roofingProject,
    roofingMaterial,
    buildingStories,
    highRangeCost,
    midRangeCost,
    lowRangeCost,
    address,
    capturedImage,
    firstname,
    lastname,
    email,
    phone,
    appointmentOneDate,
    appointmentOneTime,
    setHighRangeCost,
    setMidRangeCost,
    setLowRangeCost,
    setAppointmentOneDate,
    setAppointmentOneTime
  } = useInput();

  // Options for hours with AM/PM
  const hours = Array.from({ length: 12 }, (_, i) => {
    const hour = (i + 1).toString().padStart(2, '0');
    return [
      `${hour}:00 AM`,
      `${hour}:00 PM`
    ];
  }).flat();

  // Handle changes for hour
  const handleHourChange = (e) => {
    setAppointmentOneTime(e.target.value);
  };

  const { logAllValues } = useInput();

  // const { appointmentOneDate, setAppointmentOneDate, appointmentOneTime, setAppointmentOneTime,
  //   setHighRangeCost, setMidRangeCost, setLowRangeCost,
  //   areaOfRoof, roofingProject, roofingMaterial, buildingStories } = useInput();

  const { register, handleSubmit, setValue, formState: { errors }, trigger } = useForm();

  useEffect(() => {
    setAppointmentOneDate(appointmentOneDate);
    setAppointmentOneTime(appointmentOneTime);
  }, [setAppointmentOneDate, appointmentOneDate, setAppointmentOneTime, appointmentOneTime]);

  const handleInputChange1 = (event) => {
    setAppointmentOneDate(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setAppointmentOneTime(event.target.value);
  };


  // save details in DB
  const saveData = async () => {
    try {
      const response = await fetch('https://beta.dashboard.roofproroofing.com/store-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          zipCode,
          roofingProject,
          roofingMaterial,
          buildingStories,
          roofingProjectPrice,
          roofingMaterialPrice,
          buildingStoriesPrice,
          areaOfRoof,
          highRangeCost,
          midRangeCost,
          lowRangeCost,
          address,
          capturedImage,
          firstname,
          lastname,
          email,
          phone,
          appointmentOneDate,
          appointmentOneTime,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
      // saveData();
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const onSubmit = () => {
    if (appointmentOneDate.trim() !== '' && appointmentOneTime.trim()) {

      // Send a POST request to the API to store all the details
      fetch('https://beta.dashboard.roofproroofing.com/leads/roofing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          area: areaOfRoof,
          project_type: roofingProject,
          material: roofingMaterial,
          stories: buildingStories,
          appointment_one_date: appointmentOneDate,
          appointment_one_time: appointmentOneTime,
          // appointment_two_date: appointmentTwoDate,
          // appointment_two_time: appointmentTwoTime,
        }),
      }).then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          onNext();
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      // Send a POST request to the API to get the cost of the roof
      fetch('https://beta.dashboard.roofproroofing.com/settings/cost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          area: areaOfRoof,
          project_type: roofingProject,
          material: roofingMaterial,
          stories: buildingStories,
        }),
      }).then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          // Update the state with the new values
          setHighRangeCost(data.highTotalCost);
          setMidRangeCost(data.mediumTotalCost);
          setLowRangeCost(data.lowTotalCost);
          saveData();
          onNext();
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        
    }
  };



  return (
    <div className='items-center justify-center'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative text-3xl font-bold uppercase tracking-[12.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl">
          Roofing calculator
        </div>
        <div className="px-2 relative mt-10 text-sm font-bold uppercase tracking-[4.16px] max-sm:mt-10 max-sm:max-w-full">
          Select your appointment date and time
        </div>
        <div className='relative gap-4 text-sm justify-center text-left' style={{ marginBottom: '5%' }}>
          <div className="relative mt-14 max-w-full max-sm:mt-10">
            <div className="flex gap-5 max-sm:flex-col max-sm:gap-0 max-sm:px-4">

              <div className="flex flex-col w-[100%] max-sm:ml-0 max-sm:w-full">
                <div className="flex relative grow gap-5 justify-between max-sm:mt-10">
                  <div className="flex flex-col flex-1 self-start mt-7">
                    <div className="text-sm font-bold  uppercase tracking-[2.6px] whitespace-nowrap xl:whitespace-nowrap" style={{ textAlign: 'center' }}>
                      When do you want to meet
                    </div>

                    <input
                      className="style1-input relative mt-10 text-sm text-center whitespace-nowrap max-sm:mt-10 w-[300px]"
                      type="date"
                      style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                      placeholder="Enter date"
                      value={appointmentOneDate}
                      {...register('appointmentOneDate', { required: 'Date is required' })}
                      onChange={(e) => {
                        setAppointmentOneDate(e.target.value);
                        trigger('appointmentOneDate');
                      }}
                    />

                    {errors.appointmentOneDate && (
                      <div className="items-center text-red-600 text-sm mt-3" style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <span>{errors.appointmentOneDate.message}</span>
                      </div>
                    )}

                  <div className="flex mt-10 text-sm text-center whitespace-nowrap max-sm:mt-10 w-[300px] mx-auto">
                      <select
                        className="style1-input w-[300px] h-8 text-sm"
                        value={appointmentOneTime}
                        onChange={handleHourChange}
                      >
                        <option value="" disabled>Select Time</option>
                        {hours.map(hour => (
                          <option key={hour} value={hour}>{hour}</option>
                        ))}
                      </select>
                    </div>

                     {errors.appointmentOneTime && (
                      <div className="items-center text-red-600 text-sm mt-3" style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <span>{errors.appointmentOneTime.message}</span>
                      </div>
                    )}

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='sm:pr-3'>
          <button
            onClick={onBack}
            className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
            Back
          </button>
          <button
            type="submit"
            className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
            Next
          </button>
        </div>
      </form>

    </div>
  )
}
