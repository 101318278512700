import React from 'react'

export function MainHeaderComponent() {
  return (
    <div className="w-full">
        <div className="flex relative justify-center items-center self-stretch px-16 mt-10 pb-5 w-full text-base text-cyan-600 uppercase max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between items-center w-full max-w-[1670px] max-md:flex-wrap max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1cbb023eed7c75633baf33f94478d79e000c4c80e8b49c8964085a39bc91b09?apiKey=7d33720f90a946d189f293ed1460be5b&&"
              className="shrink-0 self-stretch max-w-full aspect-[4.55] w-[150px]"
            />
            <div className="header-links flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap max-md:max-w-full">
              <a className='nav-links' href="#">Home</a>
              <a href="#" className="nav-links flex-auto">our services</a>
              <a href="#" className="nav-links flex-auto">Who we are</a>
              <a href='#' className='nav-links'>partners</a>
              <a href='#' className='nav-links'>blogs</a>
              <a href='#' className="nav-links active">Roofing Calculator</a>
              <a href='#' className="nav-links grow whitespace-nowrap">contact us</a>
            </div>
            <a href='#' className="main-button justify-center self-stretch px-6 py-1.5 my-auto font-bold whitespace-nowrap border-2 border-cyan-600 border-solid max-md:px-5">
              <span className="">Call </span>
              <span className="">- (</span>850)-332-3330
            </a>
          </div>
        </div>
    </div>
  )
}
