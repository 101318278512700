import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useInput } from '../InputProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export function Step1Component({ onNext }) {
  const { zipCode, setZipCode } = useInput();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      zipCode: zipCode || ''
    }
  });

  useEffect(() => {
    setZipCode(zipCode);
  }, [setZipCode, zipCode]);

  useEffect(() => {
    setValue('zipCode', zipCode);
  }, [zipCode, setValue]);

  const onSubmit = (data) => {
    setZipCode(data.zipCode);
    onNext();
  };

  return (
    <div>
      <div className="self-stretch text-sm font-bold uppercase max-sm:max-w-full">
        Calculate the cost you should pay to repair or replace your roof
      </div>
      <div 
        className="letter-spacing-2x mt-7 text-4xl font-bold tracking-tighter text-cyan-600 uppercase max-sm:text-4xl">
        Get started!
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input 
          className='style1-input relative mt-10 text-sm text-center whitespace-nowrap max-sm:mt-10 w-[200px]' 
          type='text' 
          placeholder='Enter your zip code'
          {...register('zipCode', { 
            required: 'Zip code is required', 
            pattern: {
              value: /^\d{5}$/,
              message: 'Enter a valid zip code'
            }
          })}
        />
        {errors.zipCode && (
          <div className="items-center text-red-600 text-sm mt-3">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>{errors.zipCode.message}</span>
          </div>
        )}
        {!errors.zipCode && (
          <br/>
        )}
        <button 
          type='submit'
          className="main-button items-center justify-center relative justify-center items-center px-16 py-2 mt-6 max-w-full text-sm font-bold whitespace-nowrap border-2 border-cyan-600 border-solid w-[160px] max-sm:px-5 cursor-pointer">
          Next
        </button>
      </form>
    </div>
  );
}
