import React, { useState, useEffect } from 'react';
import { InputProvider } from './InputProvider';
import { MainHeaderComponent } from '../../container/header/MainHeaderComponent';
import { Step1Component } from "./steps/Step1Component";
import { FooterComponent } from "../../container/footer/FooterComponent";
import { Step2Component } from "./steps/Step2Component";
import { Step3Component } from "./steps/Step3Component";
import { Step4Component } from "./steps/Step4Component";
import { Step5Component } from "./steps/Step5Component";
import { Step6Component } from "./steps/Step6Component";
import { Step7Component } from "./steps/Step7Component";
import { Step8Component } from "./steps/Step8Component";
import { Step9Component } from "./steps/Step9Component";
import { ProgressBarComponent } from './steps/ProgressBarComponent';

export function RoofCalculator() {
  const [currentStep, setCurrentStep] = React.useState(1);

  function onNext() {
		console.log("currentStep");

    if (currentStep === 6) {
      setCurrentStep(7);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  function onBack() {
    if (currentStep === 6) {
      setCurrentStep(5);
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  function doneStage() {
    // remove initialRenderStep2 and initialRenderStep7 from localStorage
    localStorage.removeItem('initialRenderStep2');
    localStorage.removeItem('initialRenderStep7');
    window.location.reload();
  }

    // get dynamic height of the component
    useEffect(() => {
      function sendHeightToParent() {
        const height = document.documentElement.scrollHeight;
        window.parent.postMessage({ height }, '*');
      }
  
      // Send the height initially
      sendHeightToParent();
  
      // Set up an interval to periodically send the height
      const intervalId = setInterval(sendHeightToParent, 500);
  
      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }, []);


    // update height using localstorage
    useEffect(() => {
      function updateHeight() {
        const height = document.documentElement.scrollHeight;
        localStorage.setItem('calculatorHeight', height);
      }
  
      // Update height initially
      updateHeight();
  
      // Set up an interval to periodically update the height
      const intervalId = setInterval(updateHeight, 500);
  
      // Optionally, handle any height changes dynamically if needed
      window.addEventListener('resize', updateHeight);
  
      // Clean up on component unmount
      return () => {
        clearInterval(intervalId);
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

  function showStep(step) {
    switch (step) {
      case 1:
        return <Step1Component onNext={onNext} />;
      case 2:
        return <Step2Component onNext={onNext} onBack={onBack} />;
      case 3:
        return <Step3Component onNext={onNext} onBack={onBack} />;
      case 4:
        return <Step4Component onNext={onNext} onBack={onBack} />;
      case 5:
        return <Step5Component onNext={onNext} onBack={onBack} />;
      case 6:
        return <Step6Component onNext={onNext} onBack={onBack} />;
      case 7:
        return <Step7Component onNext={onNext} onBack={onBack} />;
      case 8:
        return <Step8Component onNext={onNext} onBack={onBack} />;
      case 9:
        return <Step9Component doneStage={doneStage} onBack={onBack} />;
      default:
        return <Step1Component onNext={onNext} />;
    }
  }

  return (
    <div>
        <InputProvider>
          <div>{showStep(currentStep)}</div>
        </InputProvider>
        <ProgressBarComponent currentStep={currentStep} />
    </div>
  );
}


