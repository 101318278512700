import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useInput } from '../InputProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export function Step7Component({ onNext, onBack }) {
  const { firstname, setFirstname, lastname, setLastname, email, setEmail, phone, setPhone } = useInput();
  const [isChecked, setIsChecked] = useState(false);
  const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
    defaultValues: {
      firstname: firstname || '',
      lastname: lastname || '',
      email: email || '',
      phone: phone || '',
      isChecked: false
    }
  });

  useEffect(() => {
    setFirstname(firstname);
    setLastname(lastname);
    setEmail(email);
    setPhone(phone);
  }, [setFirstname, firstname, setLastname, lastname, setEmail, email, setPhone, phone]);

  const handleInputChange1 = (event) => {
    setFirstname(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setLastname(event.target.value);
  };
  const handleInputChange3 = (event) => {
    setEmail(event.target.value);
  }  
  const handleInputChange4 = (event) => {
    setPhone(event.target.value);
  }

  // const handleNext = () => {
  //   if (firstname.trim() !== '' && lastname.trim() !== '' && email.trim() !== '' && phone.trim() !== '') {
  //     onNext();
  //   }
  // };

  const handleNext = (data) => {
    if (isChecked) {
      setFirstname(data.firstname);
      setLastname(data.lastname);
      setEmail(data.email);
      setPhone(data.phone);
      onNext();
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className='items-center justify-center'>
        <div className="relative text-3xl font-bold uppercase tracking-[5.48px] max-sm:mt-10 max-sm:max-w-full max-sm:text-4xl">
          Roofing Calculator
        </div>
        <div className="text-sm mt-2 mb-2 font-bold text-center text-dark uppercase tracking-[1.6px]">
          Verify this is your home
        </div>

        <form onSubmit={handleSubmit(handleNext)}>
          <div className="flex justify-center items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <div className="relative pl-2 pe-2">
                <input 
                  className="style1-input input-location mt-10 text-sm whitespace-nowrap w-full" 
                  type="text" 
                  style={{ textAlign: 'center' }}
                  placeholder="Enter First name"
                  onChange={handleInputChange1}
                  {...register('firstname', { required: 'First name is required' })}
                />
                {errors.firstname && (
                  <div className="items-center text-red-600 text-sm mt-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                    <span>{errors.firstname.message}</span>
                  </div>
                )}
              </div>

              <div className="relative pl-2 pe-2">
                <input 
                  className="style1-input input-location mt-10 text-sm whitespace-nowrap w-full" 
                  type="text" 
                  style={{ textAlign: 'center' }}
                  placeholder="Enter Last name"
                  onChange={handleInputChange2}
                  {...register('lastname', { required: 'Last name is required' })}
                />
                {errors.lastname && (
                  <div className="items-center text-red-600 text-sm mt-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                    <span>{errors.lastname.message}</span>
                  </div>
                )}
              </div>

              <div className="relative pl-2 pe-2">
                <input 
                  className="style1-input input-location mt-10 text-sm whitespace-nowrap w-full" 
                  type="text" 
                  style={{ textAlign: 'center' }}
                  placeholder="Enter Email"
                  onChange={handleInputChange3}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Enter a valid email address'
                    }
                  })}
                />
                {errors.email && (
                  <div className="items-center text-red-600 text-sm mt-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                    <span>{errors.email.message}</span>
                  </div>
                )}
              </div>

              <div className="relative pl-2 pe-2">
                <input 
                  className="style1-input input-location mt-10 text-sm whitespace-nowrap w-full" 
                  type="text" 
                  style={{ textAlign: 'center' }}
                  placeholder="Enter Phone Number"
                  onChange={handleInputChange4}
                  {...register('phone', { required: 'Phone number is required' })}
                />
                {errors.phone && (
                  <div className="items-center text-red-600 text-sm mt-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                    <span>{errors.phone.message}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <br/>

          <div className="relative flex justify-center items-center mt-10 px-5">
          <div>
            <input
                className="cursor-pointer form-checkbox h-5 w-4 text-primary border border-solid border-secondary-500 outline-none checked:bg-primary checked:border-primary focus:shadow-none focus:border-primary focus:border-opacity-50"
                type="checkbox"
                id="checkboxDefault"
                {...register('isChecked', { required: 'You must agree before submitting' })}
                onChange={handleCheckboxChange}
              />
              <label
                className="ml-2 cursor-pointer text-sm"
                htmlFor="checkboxDefault">
                I am the owner and/or have authority with respect to this property.
              </label>
              {errors.isChecked && (
                <div className="items-center text-red-600 text-sm mt-3">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                  <span>{errors.isChecked.message}</span>
                </div>
              )}
          </div>
          </div>
        
          <div className='sm:pr-3'>
            <button
              onClick={onBack}
              className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 mr-3 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
              Back
            </button>
            <button
              type="submit"
              className="main-button w-full items-center justify-center relative justify-center items-center px-6 py-1.5 mt-6 max-w-full text-sm font-extrabold whitespace-nowrap border-2 border-cyan-600 border-solid sm:w-[160px] max-sm:px-5 cursor-pointer">
              Next
            </button>
          </div>
        </form>

      </div>
  )
}
